/* eslint-disable react/prop-types */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';

import Seo from '../components/Seo';
import BrandGrid from '../components/BrandGrid/BrandGrid';
import DiamondCard from '../components/DiamondCard/DiamondCard';
import MemberCard from '../components/MemberCard/MemberCard';

export const pageQuery = graphql`
  query AboutUsPageQuery {
    john: file(relativePath: { eq: "members/john-deutsch.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    jared: file(relativePath: { eq: "members/jared-mauskopf.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    javi: file(relativePath: { eq: "members/javier-alonso.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    pablo: file(relativePath: { eq: "members/pablo-bullian.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    georgi: file(relativePath: { eq: "members/georgina-daian.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ruso: file(relativePath: { eq: "members/carlos-cucurullo.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    robert: file(relativePath: { eq: "members/robert-nakahara.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

const AboutUs = ({ data }) => {
  return (
    <>
      <Seo
        title="About Us · Medical Web Experts"
        description="Medical Web Experts builds custom websites and offers advanced internet marketing services. We have been helping healthcare industry businesses grow since 2003."
        canonical="/about-us/"
        schema={`
        {
          "@context": "http://schema.org/",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": "1",
              "item": {
                "@id": "https://www.medicalwebexperts.com",
                "name": "HOME",
                "image": "https://www.medicalwebexperts.com/images/mwe.png"
              }
            },
            {
              "@type": "ListItem",
              "position": "2",
              "item": {
                "@id": "https://www.medicalwebexperts.com/about-us/",
                "name": "HEALTHCARE'S TRUSTED PROVIDER OF WEB DEVELOPMENT AND TECHNOLOGY SOLUTIONS SINCE 2003"
              }
            }
          ]
        }
        `}
      />
      <BrandGrid colored />
      {/* Pages with Img Main Hero */}
      <div className="d-flex align-items-sm-center full-height-lg ui-layout__main--nmt">
        <Container>
          <Row className="align-items-center justify-content-lg-between">
            <Col lg={{ span: 5, offset: 1 }}>
              <h1 className="mb-5 text-center text-lg-start h1--center-lg">
                Your Success.
                <br />
                Our Team
              </h1>
              <p className="mt-4 mb-5 font-size-lg font-weight-light  text-center text-lg-start">
                Healthcare&apos;s Trusted Provider of Web Development and
                Technology Solutions since 2003
              </p>
              <div className="text-center text-lg-start mb-4">
                <Button as={Link} variant="primary" to="/contact-us">
                  start Now
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <hr className="my-6 no-border" />

      {/* About Content */}
      <Container>
        {/* Our Partners */}

        {/* Management */}
        <Row className="mb-5">
          <Col className="text-center">
            <h2 className="h1 h1--center">Meet Our Management</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <MemberCard
              featuredImage={data.john}
              name="John Deutsch"
              linkedin="https://www.linkedin.com/in/johnnydeutsch/"
              position="Founder and Board Member"
              bio="John is a seasoned executive with 18 years of healthcare IT business ownership experience, specializing in HIPAA compliance, patient engagement, marketing, and software/web development. He takes an active role in the architecture and design of custom development projects and products produced by Medical Web Experts. John is also the founder and CEO of Universe mHealth and Bridge Patient Portal."
            />
          </Col>
          <Col lg={6}>
            <MemberCard
              featuredImage={data.jared}
              name="Jared Mauskopf"
              linkedin="https://www.linkedin.com/in/jaredmauskopf/"
              position="Chief Executive Officer"
              bio="Jared has been with MWE since 2013 and is based in Brooklyn, NY.  Having worked in our account management, project management, and marketing departments, he has brought dozens of projects to completion for key clients.  He became CEO at the start of 2020 and continues to work directly with clients on major projects.  Jared holds a B.A. from Boston University."
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <MemberCard
              featuredImage={data.javi}
              name="Javier Alonso Shannon"
              linkedin="https://www.linkedin.com/in/javieralonsoshannon/"
              position="Director of Technology"
              bio="Javier has been with MWE since 2012.  He not only manages MWE’s full technical team (including coding, code revision, and training), but also plays an integral role in strategy development for MWE and its clients. He approaches solutions from a perspective that balances compliance, performance, functionality, and usability."
            />
          </Col>
          <Col lg={6}>
            <MemberCard
              featuredImage={data.pablo}
              name="Pablo Bullian"
              linkedin="https://www.linkedin.com/in/pbullian/"
              position="Chief Information Security Officer"
              bio="Pablo architected and manages MWE’s HIPAA-compliant hosting infrastructure. He is an Amazon Web Services (AWS) Certified Solutions Architect, Certified Information Systems Security Professional (CISSP), and Cisco Certified Network Associate (CCNA). Pablo has an M.S. in Cybersecurity from the University of Buenos Aires and is an associate professor of cybersecurity engineering at CPE Lyon in France."
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <MemberCard
              featuredImage={data.georgi}
              name="Georgina Daian"
              linkedin="https://www.linkedin.com/in/georginadaian/"
              position="Director of Project Management"
              bio="Georgina has over 15 years of experience implementing complex projects across multiple industries, including manufacturing, professional services, and technology. During her career, she's gained the trust of leading enterprise clients to define and deliver innovative solutions. Georgina's experience, academic background, and passion for learning, combined with her focus on process optimization, allow her to take an integral approach to how she leads projects. She holds a Bachelor's in Engineering and a Master's in Business Administration."
            />
          </Col>
          <Col lg={6}>
            <MemberCard
              featuredImage={data.robert}
              name="Robert Nakahara"
              linkedin="https://www.linkedin.com/in/robert-nakahara-3693b71a/"
              position="Financial Advisor"
              bio="Robert is a proven leader with an extensive background in the healthcare, pharmaceutical, and financial industries. He holds an MBA in Finance from the University of Puget Sound and a BA in Accounting/Finance/International Business from the University of Washington. Robert was CFO for multiple healthcare organizations, including InDemand Interpreting, Inc., Washington Health Benefit Exchange (aka Obamacare), and Premera Blue Cross, and was Senior Auditor for PriceWaterhouseCoopers and KPMG."
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <MemberCard
              featuredImage={data.ruso}
              name="Carlos “Ruso” Cucurullo"
              linkedin="https://www.linkedin.com/in/rusocucu/"
              position="Creative Director"
              bio="Ruso has been Design & User Experience Director at Medical Web Experts for over 10 years. He has a unique ability to help clients verbalize their desired design language, and tailors the design, user interface, and user experience of every project to the client’s unique goals."
            />
          </Col>
        </Row>

        <hr className="my-6 no-border" />
        <Row className="align-items-center justify-content-between">
          <Col lg={5}>
            <h2 className="h1 text-center text-lg-start h1--center-lg">
              Our Partners
            </h2>
            <p>
              We’re dedicated to building strong partnerships with other
              companies and service providers who can help you be successful
              online. Take a look at some of our featured partners.
            </p>
            <p>
              Since 2003, we have been using the power of the digital medium to
              grow healthcare industry businesses, including our own.
            </p>
          </Col>
          <Col
            lg={7}
            className="d-flex flex-wrap justify-content-center justify-content-sm-between"
          >
            <StaticImage
              src="../images/site-core/logos/logo-bridge.jpg"
              alt="Bridge Patient Portal"
            />
            <StaticImage
              src="../images/site-core/logos/logo-universe.jpg"
              alt="Universe mHealth"
            />
            <StaticImage
              src="../images/site-core/logos/logo-rebox.jpg"
              alt="Redox"
            />
            <StaticImage
              src="../images/site-core/logos/logo-qvera.jpg"
              alt="Qvera"
            />
            <StaticImage
              src="../images/site-core/logos/logo-aws.jpg"
              alt="Amazon Web Services"
            />
            <StaticImage
              src="../images/site-core/logos/logo-bing.jpg"
              alt="Bing"
            />
            <StaticImage
              src="../images/site-core/logos/logo-md.jpg"
              alt="MD Marketing digital"
            />
            <StaticImage
              src="../images/site-core/logos/logo-google.jpg"
              alt="Google"
            />
            <StaticImage
              src="../images/site-core/logos/logo-validic.jpg"
              alt="Validic"
            />
          </Col>
        </Row>

        <hr className="my-6 no-border" />
        {/* Our history */}
        <Row className="justify-content-center justify-content-xl-around">
          <Col md={6} xl={3}>
            <h2 className="h1 text-center text-xl-start h1--center-xl">
              Our Company&apos;s History
            </h2>
            <p className="text-center text-xl-start">
              We’re dedicated to building strong partnerships with other
              companies and service providers who can help you be successful
              online. Take a look at some of our featured partners.
            </p>
          </Col>
          <Col xl={{ span: 6, offset: 1 }}>
            <Carousel className="ui-history-carousel">
              <Carousel.Item className="py-5 px-3 p-sm-5">
                <DiamondCard
                  year="2003"
                  description="Medical Web Experts is born. First website is completed."
                />
              </Carousel.Item>
              <Carousel.Item className="py-5 px-3 p-sm-5">
                <DiamondCard
                  variant="secondary"
                  year="2005"
                  description="MWE starts offering SEO and blogging services."
                />
              </Carousel.Item>
              <Carousel.Item className="py-5 px-3 p-sm-5">
                <DiamondCard
                  variant="accent"
                  year="2007"
                  description="MWE opens international office in Buenos Aires, Argentina."
                />
              </Carousel.Item>
              <Carousel.Item className="py-5 px-3 p-sm-5">
                <DiamondCard
                  year="2009"
                  description="MWE starts developing Bridge Patient Portal"
                />
              </Carousel.Item>
              <Carousel.Item className="py-5 px-3 p-sm-5">
                <DiamondCard
                  year="2010"
                  description="MWE expands internationally and now has clients on four continents."
                />
              </Carousel.Item>
              <Carousel.Item className="py-5 px-3 p-sm-5">
                <DiamondCard
                  year="2011"
                  description="MWE embraces change to HTML5/CSS3, building all sites in the new standard and optimized for mobile."
                />
              </Carousel.Item>
              <Carousel.Item className="py-5 px-3 p-sm-5">
                <DiamondCard
                  year="2014"
                  description="Bridge Patient Portal receives ONC certification for Health Information Technology."
                />
              </Carousel.Item>
              <Carousel.Item className="py-5 px-3 p-sm-5">
                <DiamondCard
                  year="2015"
                  description="MWE expands its services into mobile application development, releasing its first iOS application."
                />
              </Carousel.Item>
              <Carousel.Item className="py-5 px-3 p-sm-5">
                <DiamondCard
                  year="2017"
                  description="MWE launches Universe mHealth, the healthcare industry's first turn-key mobile app platform."
                />
              </Carousel.Item>
              <Carousel.Item className="py-5 px-3 p-sm-5">
                <DiamondCard
                  year="2018"
                  description="MWE showcases its products and services for the first time at HIMSS 2018, the healthcare industry's largest trade show."
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutUs;
