import React from 'react';
import { string } from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Diamond from '../Diamond/Diamond';

const propTypes = {
  year: string.isRequired,
  description: string.isRequired,
};

const DiamondCard = ({ year, description }) => {
  return (
    <Row className="align-items-center flex-column flex-sm-row">
      <Col className="col-auto mx-auto">
        <Diamond variant="primary">
          <p className="font-size-lg text-white m-0 font-weight-bold">{year}</p>
        </Diamond>
      </Col>
      <Col className="mt-5 mt-sm-0 ms-0 ms-sm-4 text-center text-sm-start ui-history-font-size">
        {description}
      </Col>
    </Row>
  );
};

DiamondCard.propTypes = propTypes;

export default DiamondCard;
