import React from 'react';
import { string, shape, node } from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkedinIcon } from 'react-share';

const propTypes = {
  featuredImage: shape({}).isRequired,
  name: string.isRequired,
  linkedin: string.isRequired,
  position: string.isRequired,
  bio: node.isRequired,
};

const MemberCard = ({ position, name, linkedin, featuredImage, bio }) => {
  return (
    <Row className="mb-4 mb-sm-6 ui-member-row">
      <Col sm={4}>
        <GatsbyImage
          image={getImage(featuredImage)}
          alt={name}
          className="ui-member-picture"
        />
      </Col>
      <Col lg={7} sm={8}>
        <h4 className="mb-1 d-flex align-items-center mt-4 mt-sm-0">
          {name}
          <a href={linkedin} target="_blank" rel="noreferrer" className="ms-2">
            <LinkedinIcon
              size={28}
              bgStyle={{ fill: '#2D95E5' }}
              iconFillColor="white"
              borderRadius={50}
            />
          </a>
        </h4>
        <p className="text-muted font-size-xs">{position}</p>
        <p className="font-size-sm">{bio}</p>
      </Col>
    </Row>
  );
};

MemberCard.propTypes = propTypes;

export default MemberCard;
